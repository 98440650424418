/* .testimonials {
    margin-bottom:50px;
  } */

  .testimonials-main{
    margin-top: 100px;
    padding-bottom:100px;
  } 

  .testimonials-title{
    margin-bottom: 20px;

  }

  .card-title-rounded{
    width: 40px;
    height: 40px;
    background-color: #333333;
    border-radius: 50px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: flex-end;
    margin-bottom: 10px;
  }

  /* .testimonials {
    background: url('../../assets/images/Image-target-min.png');
    position: relative;
    width: 100%;
    height: 100vh;
    background-color: #333333;
    text-shadow: 0 0.05rem 0.1rem rgba(0, 0, 0, 0.5);
    box-shadow: inset 0 0 5rem rgba(0, 0, 0, 0.5);
    background: url('../../assets/images/bg-range-target-min.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    overflow: hidden;
  } */

  .testimonials-card{
    color: #fff!important;
    background-color: #4b4b4b;
    font-size: 12px;
    word-break: break-all;
  }

  .p-testimonials{
    word-break: break-word;
    /* font-size: 14px;
    background-color: #00000060;
    border-radius: 3px;
    padding: 8px; */
  }
  
  .text-bg-custom{
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #333;
  background-clip: border-box;
  border: 1px solid rgb(255 255 255);
  border-radius: 0.25rem;
  font-size: 14px;
}

.text-bg-custom p{
  font-size: 14px;
}

.card-title-testimonial{
  position: absolute;
  top: 5px;
  left: 5px;
  background-color: #333;
  padding: 3px;
  border-radius: 50%;
  width: 50px;
  height: 50px;
}

.card-title-testimonial h5{
  position: relative;
  top: 50%;
  left: 62%;
  transform: translate(-50%, -50%);
}


