@import url('https://fonts.googleapis.com/css2?family=Audiowide&display=swap');

*,
::before,
::after {
  box-sizing: border-box;
  /* margin: 0;
  padding: 0; */
  /* font-family: 'Audiowide', cursive; */
  /* background-color: #333333; */
  /* color: #ffffff; */
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Audiowide', cursive;
  width: 100%;
  color: #ffffff;
  height: 100vh;
  background-color: #333333;
  /* text-shadow: 0 0.05rem 0.1rem rgba(0, 0, 0, 0.5); */
  /* box-shadow: inset 0 0 5rem rgba(0, 0, 0, 0.5); */
}

ol,
ul {
  list-style: none;
}

a {
  color: #ffffff;
  text-decoration: none;
}
