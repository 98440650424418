.video-container{
    display: flex;
flex-direction: row;
flex-wrap: nowrap;
align-content: center;
justify-content: center;
align-items: center;
}

.video-bg{
    border-radius: 0.25rem;
    width: 85%;
}