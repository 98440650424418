/* .about {
  position: relative;
  width: 100%;
  height: 100vh;
  background: url('../../assets/images/bg-range-target-min.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  overflow: hidden;
} */

.main-about{
/* background: url('../../assets/images/Image-target-min.png'); */
position: relative;
width: 100%;
height: 100vh;
background: url('../../assets/images/bg-range-target-min.png');
background-repeat: no-repeat;
background-size: cover;
background-position: center;
overflow: hidden;
}

.bg-about-image1 {
  position: absolute;
  left: 30%;
  top: 70%;
  text-align: center;
  justify-content: center;
  align-items: center;
  transform: translate(-50%, -50%);
  animation: 1s ease-out 0s 1 moveImgAbout1;
}

.bg-about-image2 {
  position: absolute;
  left: 70%;
  top: 35%;
  text-align: center;
  justify-content: center;
  align-items: center;
  transform: translate(-50%, -50%);
  animation: 1s ease-out 0s 1 moveImgAbout2;
}

.about-h2 {
  font-size: calc(1rem + 0.9vw);
}

@keyframes moveImgAbout1 {
  0% {
    left: 30%;
    top: 35%;
  }
  100% {
    left: 30%;
    top: 70%;
  }
}

@keyframes moveImgAbout2 {
  0% {
    left: 70%;
    top: 70%;
  }
  100% {
    left: 70%;
    top: 35%;
  }
}

.about-container {
  width: 350px;
  height: 500px;
  background: inherit;
  position: absolute;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  top: 50%;
  left: 50%;
  margin-left: -175px;
  margin-top: -250px;
  border-radius: 7px;
}

.about-container:before {
  width: 400px;
  height: 550px;
  content: '';
  position: absolute;
  top: -25px;
  left: -25px;
  bottom: 0;
  right: 0;
  background: inherit;
  box-shadow: inset 0 0 0 200px rgba(255, 255, 255, 0.2);
  filter: blur(10px);
}

.about-card {
  z-index: 1000;
  min-width: 350px;
  position: absolute;
  /* padding: 10px; */
  /* padding-right: 5px;
  padding-left: 5px; */
  left: 50%;
  top: 50%;
  text-align: center;
  justify-content: center;
  align-items: center;
  transform: translate(-50%, -50%);
}

@media only screen and (max-width: 600px) {
  .about-card {
    z-index: 1000;
    min-width: 350px;
    position: absolute;
    /* padding: 10px; */
    padding-right: 5px;
    padding-left: 5px;
    left: 50%;
    top: 50%;
    text-align: center;
    justify-content: center;
    align-items: center;
    transform: translate(-50%, -50%);
  }

  .about-container {
    width: 350px;
    height: 500px;
    background: inherit;
    position: absolute;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    top: 50%;
    left: 50%;
    margin-left: -175px;
    margin-top: -250px;
    border-radius: 7px;
  }

  .about-container:before {
    width: 400px;
    height: 550px;
    content: '';
    position: absolute;
    top: -25px;
    left: -25px;
    bottom: 0;
    right: 0;
    background: inherit;
    box-shadow: inset 0 0 0 200px rgba(255, 255, 255, 0.2);
    filter: blur(10px);
  }

  .about-card {
    z-index: 1000;
    min-width: 350px;
    position: absolute;
    /* padding: 10px; */
    /* padding-right: 5px;
    padding-left: 5px; */
    left: 50%;
    top: 50%;
    text-align: center;
    justify-content: center;
    align-items: center;
    transform: translate(-50%, -50%);
  }
}

/* anim btn */

.custom-btn {
  position: relative;
  color: #fff;
  background: transparent;
  border: 2px solid #fff;
}

/* 11 */
.btn-contact-about {
  overflow: hidden;
  transition: all 0.3s ease;
}
.btn-contact-about:hover {
  background: #ffffff45;
  color: #222;
}
.btn-contact-about:before {
  position: absolute;
  content: '';
  display: inline-block;
  top: -180px;
  left: 0;
  width: 30px;
  height: 100%;
  background-color: #fff;
  animation: shiny-btn 3s ease-in-out infinite;
}
.btn-contact-about:active {
  box-shadow: 4px 4px 6px 0 rgba(255, 255, 255, 0.3),
    -4px -4px 6px 0 rgba(116, 125, 136, 0.2),
    inset -4px -4px 6px 0 rgba(255, 255, 255, 0.2),
    inset 4px 4px 6px 0 rgba(0, 0, 0, 0.2);
}

@keyframes shiny-btn {
  0% {
    -webkit-transform: scale(0) rotate(45deg);
    opacity: 0;
  }
  80% {
    -webkit-transform: scale(0) rotate(45deg);
    opacity: 0.5;
  }
  81% {
    -webkit-transform: scale(4) rotate(45deg);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(50) rotate(45deg);
    opacity: 0;
  }
}

/* anim drapeau france about */
.bounce-in-right {
  -webkit-animation: bounce-in-right 1.1s 1s both;
  animation: bounce-in-right 1.1s 1s both;
}
@-webkit-keyframes bounce-in-right {
  0% {
    -webkit-transform: translateX(600px);
    transform: translateX(600px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }
  38% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    opacity: 1;
  }
  55% {
    -webkit-transform: translateX(68px);
    transform: translateX(68px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  72% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  81% {
    -webkit-transform: translateX(32px);
    transform: translateX(32px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  90% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  95% {
    -webkit-transform: translateX(8px);
    transform: translateX(8px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}
@keyframes bounce-in-right {
  0% {
    -webkit-transform: translateX(600px);
    transform: translateX(600px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }
  38% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    opacity: 1;
  }
  55% {
    -webkit-transform: translateX(68px);
    transform: translateX(68px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  72% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  81% {
    -webkit-transform: translateX(32px);
    transform: translateX(32px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  90% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  95% {
    -webkit-transform: translateX(8px);
    transform: translateX(8px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}

.about-biography{
  margin-top: 20px;
  font-size: 13px;
  padding-bottom: 100px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: center;
}
.biography{
border-radius: 0.25rem;
background-color: #4b4b4b;
padding: 20px;

}

.about-biography h3{
  /* text-decoration: underline; */
  text-align: center;
  font-size: 24px;
}
.about-biography h4{
  font-size: 20px;

}
.about-biography p{
  font-size: 14px;

}


