.modal-content {
  background-color: #0000006e;
  color: #333;
}

.title-contact {
  font-size: calc(0.6rem + 0.9vw);
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #ffffff;
  background-color: #ffffff45;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-select {
  background-color: #ffffff45;
  color: #ffffff;
}

.modal-backdrop {
  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.14);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5.6px);
  -webkit-backdrop-filter: blur(5.6px);
}

.modal-backdrop.show {
  opacity: 1;
}

.form-select option {
  /* position: absolute; */
  background-color: #ffffff;
  color: #333;
  /* top: 100%;
  left: 0;
  right: 0;
  z-index: 99; */
}

.form-error {
  color: red;
}
.success-message {
  text-align: center;
  margin: 10px;
}

.recaptcha-div {
  margin: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
