.home {
  position: relative;
  width: 100%;
  height: 100vh;
  background: url('../../assets/images/Image-target-min.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  overflow: hidden;
}
.bg-image1 {
  position: absolute;
  left: 30%;
  top: 35%;
  text-align: center;
  justify-content: center;
  align-items: center;
  transform: translate(-50%, -50%);
  animation: 1s ease-out 0s 1 moveImgHome1;
}

.bg-image2 {
  position: absolute;
  left: 70%;
  top: 70%;
  text-align: center;
  justify-content: center;
  align-items: center;
  transform: translate(-50%, -50%);
  animation: 1s ease-out 0s 1 moveImgHome2;
}

@keyframes moveImgHome1 {
  0% {
    left: 30%;
    top: 70%;
  }
  100% {
    left: 30%;
    top: 35%;
  }
}

@keyframes moveImgHome2 {
  0% {
    left: 70%;
    top: 35%;
  }
  100% {
    left: 70%;
    top: 70%;
  }
}
.bg-image {
  width: 60%;
}

.bg-card {
  z-index: 1000;
  min-width: 300px;
  position: absolute;
  padding: 10px;
  left: 50%;
  top: 50%;
  text-align: center;
  justify-content: center;
  align-items: center;
  transform: translate(-50%, -50%);
  background-color: #33333378;
  border-radius: 7px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}

.france {
  position: relative;
  margin: 20px;
  height: 5px;
  background: -webkit-linear-gradient(
    left,
    #002395 33%,
    #ffffff 33%,
    #ffffff 66%,
    #ed2939 66%
  );
  background: linear-gradient(
    to right,
    #002395 33%,
    #ffffff 33%,
    #ffffff 66%,
    #ed2939 66%
  );
}

.navbar-dark .navbar-nav .nav-link {
  color: #ffffff;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.lead2 {
  font-size: 1rem;
  font-weight: 100;
}

.btn-secondary {
  color: #333333 !important;
  background-color: #ffffff !important;
  text-shadow: none;
  text-decoration: none;
}

.btn-secondary:hover {
  color: #ffffff !important;
  background-color: #333333 !important;
  text-shadow: none;
  text-decoration: none;
}

/* anim btn */

.custom-btn {
  position: relative;
  color: #fff;
  background: transparent;
  border: 2px solid #fff;
}

/* anim btn contact */
.btn-contact-home {
  overflow: hidden;
  transition: all 0.3s ease;
}
.btn-contact-home:hover {
  background: #0000008a;
  color: #fff;
}
.btn-contact-home:before {
  position: absolute;
  content: '';
  display: inline-block;
  top: -180px;
  left: 0;
  width: 30px;
  height: 100%;
  background-color: #fff;
  animation: shiny-btn 3s ease-in-out infinite;
}
.btn-contact-home:active {
  box-shadow: 4px 4px 6px 0 rgba(255, 255, 255, 0.3),
    -4px -4px 6px 0 rgba(116, 125, 136, 0.2),
    inset -4px -4px 6px 0 rgba(255, 255, 255, 0.2),
    inset 4px 4px 6px 0 rgba(0, 0, 0, 0.2);
}

@keyframes shiny-btn {
  0% {
    -webkit-transform: scale(0) rotate(45deg);
    opacity: 0;
  }
  80% {
    -webkit-transform: scale(0) rotate(45deg);
    opacity: 0.5;
  }
  81% {
    -webkit-transform: scale(4) rotate(45deg);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(50) rotate(45deg);
    opacity: 0;
  }
}

.about-link {
  box-shadow: 0 -2px 0 0 #ffffff inset;
  transition: all 0.3s ease-out;
  font-style: oblique;
  color: #ffffffad;
}

.about-link:hover {
  padding: 0.2em 0;
  color: #ffffff;
  box-shadow: 0 -2px 0 0 #ffffff inset;
  transition: all 0.1s ease-out;
  /* box-shadow: 0 2px 0 0 #002395 inset, 0 -2px 0 0 #e22d3c inset; */
}

/* anim drapeau france home */
.bounce-in-left {
  -webkit-animation: bounce-in-left 1.1s 1s both;
  animation: bounce-in-left 1.1s 1s both;
}

@-webkit-keyframes bounce-in-left {
  0% {
    -webkit-transform: translateX(-600px);
    transform: translateX(-600px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }
  38% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    opacity: 1;
  }
  55% {
    -webkit-transform: translateX(-68px);
    transform: translateX(-68px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  72% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  81% {
    -webkit-transform: translateX(-28px);
    transform: translateX(-28px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  90% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  95% {
    -webkit-transform: translateX(-8px);
    transform: translateX(-8px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}
@keyframes bounce-in-left {
  0% {
    -webkit-transform: translateX(-600px);
    transform: translateX(-600px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }
  38% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    opacity: 1;
  }
  55% {
    -webkit-transform: translateX(-68px);
    transform: translateX(-68px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  72% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  81% {
    -webkit-transform: translateX(-28px);
    transform: translateX(-28px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  90% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  95% {
    -webkit-transform: translateX(-8px);
    transform: translateX(-8px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}
