.b-example-divider {
  height: 3rem;
  background-color: rgba(0, 0, 0, 0.1);
  border: solid rgba(0, 0, 0, 0.15);
  border-width: 1px 0;
  box-shadow: inset 0 0.5em 1.5em rgba(0, 0, 0, 0.1),
    inset 0 0.125em 0.5em rgba(0, 0, 0, 0.15);
}

.bi {
  vertical-align: -0.125em;
  fill: currentColor;
}

.footer {
  background-color: #333333bd;
  z-index: 888;
}

.Termes-and-conditions-link-footer {
  color: #1d6ce4;
  font-size: 12px;
}

@media only screen and (max-width: 600px) {
  .bg-brand {
    /* display: none; */
    font-size: 12px;
  }
}
